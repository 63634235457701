import "./App.css";
import Hero from "./components/Hero/Hero";
import Virtual from "./components/Virtual/Virtual";
import Header from './components/header/Header.jsx'
import Slider from './components/Slider/Slider.jsx'
import Product from "./components/Products/Product";
import Testimonials from "./components/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
     <Header/>
     <Hero/>
     <Slider/>
     <Virtual/>
     <Product/>
     <Testimonials/>
     <Footer/>
    </div>
  );
}

export default App;
