import React from 'react'
import css from './Hero.module.css'
// import HeroImg from '../../assets/hero.png'
import lakme from '../../assets/LAKME-ABSOLUTE.jpg'
import lipstick from '../../assets/Lip-Oils-0.jpg'
import {RiShoppingBagFill} from 'react-icons/ri'
import {BsArrowRight} from 'react-icons/bs'
import {motion} from 'framer-motion'



const Hero = () => {

  const transition ={duration :3,type: "spring"}
  return (
    <div className={css.container}>
          

          {/*left side*/}
          <div className={css.h_sides}>
            <span className={css.text1}>Skin protection cream</span>

            <div className={css.text2}>
                <span>Trendy Collection</span>

                <span>Seedly say has suitable disposal and boy.Exercise joy man and children rejoiced.</span>
            </div>
          </div>
 
        {/*Middle side hero image*/}
           <div className={css.wrapper}>
                 {/* Bluecircle animation */}
                <motion.div 
                  initial = {{bottom:"2rem"}}
                  whileInView={{bottom:"0rem"}}
                  className={css.blueCircle}
                  transition={transition}>
                </motion.div>

                  {/* /*hero image*/ }
                <motion.img
                   initial = {{bottom:"-2rem"}}
                   whileInView={{bottom:"0rem"}}
                   transition={transition}
                   src={lipstick} alt ="heroimage"/>
               

                  {/* cart div animating */}
                <motion.div
                   initial = {{right : "4%"}}
                   whileInView={{right:"2%"}}
                   transition={transition}
                   className={css.cart2}>
                    <RiShoppingBagFill/>
                
                    <div className={css.signup}>
                        <span>Best Signup Offers</span>
                        <div>
                          <BsArrowRight/>
                        </div> 
                    </div>
                </motion.div>
           </div>
           
           {/* {Right Side} */}

           <div className={css.h_sides}>
               <div className={css.traffic}>
                    <span>1.5m</span>
                    <span>Monthly Traffic</span>
               </div>

               <div className={css.customers}>
                     <span>100k</span>
                     <span>Happy Customers</span>
               </div>
           </div>








    </div>
  )
}

export default Hero